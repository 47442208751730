import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoArrowUndoSharp, IoCheckmarkCircle, IoClose, IoCloseCircleSharp, IoWarning } from "react-icons/io5";
import { PortaleContext } from '../../store/portale-context';
import { AuthContext } from '../../store/auth-context';
import ModalLoading from './ModalLoading';
import ModalError from './ModalError';

function ModalAggiungiTipoRichiesta({setModalAggiungiTipoRichiesta, visible, aggiornaDatiPortale, dataDal, dataAl, getDefaultFromDate, getCurrentDate }) {

    const portaleCtx = useContext(PortaleContext);
    const authCtx = useContext(AuthContext);
    const [dati, setDati] = useState({
        nome: '',
    });
    const [modalError, setModalError] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [modalLoading, setModalLoading] = useState(false);

    useEffect(() => {
        setDati({nome: ''});
    }, [visible]);

    const aggiungiTipoRichiesta = () => {
        setModalLoading(true);

        var data = {
            'op': 'aggiungiTipoRichiesta',
            'Nome': dati.nome,
            'tipo': portaleCtx.activeTab,
            'dataDal': dataDal == '' ? getDefaultFromDate() : dataDal,
            'dataAl': dataAl == '' ? getCurrentDate() : dataAl,
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        setModalError({flgModal: true, titolo: 'ATTENZIONE', testo: responseJson.errore})
                        return;
                    }

                    aggiornaDatiPortale(responseJson);
                    setModalAggiungiTipoRichiesta(false);

                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({flgModal: true, titolo: 'ATTENZIONE', testo: "Errore nel salvataggio del tipo richiesta"})
                })
        );
    }

    return(
            <Modal
                isOpen={visible}
                onRequestClose={() => setModalAggiungiTipoRichiesta(false)}
                className="info-modal"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >                
                <div>
                    <IoClose
                        className="close-icon"
                        onClick={() => setModalAggiungiTipoRichiesta(false)}
                    />
                </div>

                <h3 className="info-modal-title" style={{marginTop: '0px'}}> 
                    Aggiungi tipo richiesta
                </h3>

                <div className='contact-form'>

                    <label className="input-filtro-cliente-articoli form-input">
                        <div className='etichetta'>Descrizione tipo richiesta</div>
                        <input
                            className='valori'
                            type="text"
                            placeholder=""
                            value={dati.nome}
                            style={{width: '65%'}}
                            maxLength={50}
                            onChange={(e) => setDati({ ...dati, nome: e.target.value })}
                        />
                    </label>

                    <div className="buttons" style={{width: '90%'}}>
                        <button
                            className="button red"
                            onClick={() => {setModalAggiungiTipoRichiesta(false)}}>
                            Annulla <IoArrowUndoSharp className="text-icon-right" />
                        </button>
                        <button
                            className="button green"
                            onClick={() => {
                                aggiungiTipoRichiesta();
                            }}
                        >
                            Salva <IoCheckmarkCircle className="text-icon-right" />
                        </button>
                    </div>
                </div>

                <ModalError setModalError={setModalError} modalState={modalError}/>
                <ModalLoading visible={modalLoading} color="#063970" />

            </Modal>
    );
}

export default ModalAggiungiTipoRichiesta;