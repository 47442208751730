import React, { useState, useLayoutEffect, useRef, useContext, useEffect } from 'react';
import { IoAttach, IoAttachOutline, IoClose, IoCloseCircleSharp, IoSendSharp } from 'react-icons/io5';
import Modal from 'react-modal';
import { AuthContext } from '../../store/auth-context';
import { PortaleContext } from '../../store/portale-context';

const ModalChat = ({ visible, setModalChat, messaggi, inviaMessaggio, setModalError, flgVistati, scaricaAllegati }) => {
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);
    const authCtx = useContext(AuthContext);
    const portaleCtx = useContext(PortaleContext);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [selectedFiles, setSelectedFiles] = useState(null);

    const fileInputRef = useRef(null);

    useLayoutEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messaggi]);

    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
            inviaMessaggio(newMessage, selectedFiles);
            setSelectedFiles(null);
            setNewMessage('');
        }
    };

    //funzioni grafiche
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    const selectErrorStyle = {
        control: (base, state) => ({
            ...base,
            borderColor: "#fca8a8",
            background: "#fdeded",
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
        window.addEventListener('resize', handleWindowResize);
        // Clean up: rimuovi l'event listener quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, []);

    const renderTextWithLineBreaks = (text) => {
        const lines = text.split(/\r\n|\r|\n/); // Split the text on different newline characters
        return (
            lines.map((line, index) => (
                <div style={{ textAlign: 'left', wordBreak: 'break-word' }} key={index}>
                    {line}
                    {index !== lines.length - 1 && <br />}
                </div>
            ))
        );
    };

    //allegati
    const handleScegliAllegato = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files);
    };

    //render allegati
    const isImage = (fileName) => {
        return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
    };

    const getFileNameFromUrl = (url) => {
        // Split the URL by slashes and get the last part
        return url.split('/').pop();
    };

    // Utility function to render the sender name
    const renderSenderName = (senderId, utenti) => {
        const user = utenti.find(user => user.CodCliFor === senderId);
        return user ? user.Nome : '';
    };

    return (
        <Modal
            isOpen={visible}
            onRequestClose={() => { setNewMessage(''); setModalChat(false) }}
            ariaHideApp={false}
            className="info-modal-chat"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                    zIndex: 10,
                },
                content: {
                    zIndex: 11,
                },
            }}
        >
            <div style={{ flex: 0.1, display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                    <div>
                        <IoClose
                            className="close-icon"
                            onClick={() => { setNewMessage(''); setModalChat(false) }}
                        />
                    </div>
                    <div className="info-modal-title" style={{ fontWeight: 'inherit', marginBottom: '7px', justifyContent: 'flex-start' }}>
                        <b style={{ marginRight: '5px' }} >{portaleCtx.testata.DesTipoRichiesta}</b> N. <b style={{ marginLeft: '5px' }}>{portaleCtx.testata.Id}</b>
                    </div>
                    <div className="info-modal-title" style={{ fontWeight: 'inherit', marginBottom: '7px', justifyContent: 'flex-start' }}>
                        Da <b style={{ marginLeft: '5px', marginRight: '5px' }} >{portaleCtx.testata.NomeMittente}</b> il <b style={{ marginLeft: '5px', marginRight: '5px' }} >{portaleCtx.testata.DataApertura}</b>alle <b style={{ marginLeft: '5px', marginRight: '5px' }} >{portaleCtx.testata.OraApertura}</b>
                    </div>
                    {
                        portaleCtx.testata.DataScadenza != '01/01/1900' &&
                        <div className="info-modal-title" style={{ fontWeight: 'inherit', marginBottom: '7px', justifyContent: 'flex-start' }}>
                            Scadenza: <b style={{ marginLeft: '5px', marginRight: '5px' }} >{portaleCtx.testata.DataScadenza} </b>
                        </div>
                    }
                    <div className="info-modal-title" style={{ fontWeight: 'inherit', marginBottom: '7px', justifyContent: 'flex-start' }}>
                        Cliente: <b style={{ marginLeft: '5px', marginRight: '5px' }} >{portaleCtx.testata.RagSocCliente}</b>
                    </div>
                    {
                        portaleCtx.testata.Commessa != '' &&
                        <div className="info-modal-title" style={{ fontWeight: 'inherit', marginBottom: '7px', justifyContent: 'flex-start' }}>
                            Commessa: <b style={{ marginLeft: '5px', marginRight: '5px' }} >{portaleCtx.testata.Commessa} </b>
                        </div>
                    }
                    {
                        portaleCtx.testata.NomiDestinatari != undefined &&
                        portaleCtx.testata.NomiDestinatari.includes(',') != '' &&
                        <div className="info-modal-title" style={{ fontWeight: 'inherit', marginBottom: '7px', justifyContent: 'flex-start' }}>
                            Destinatari: <b style={{ marginLeft: '5px', marginRight: '5px' }} >{portaleCtx.testata.NomiDestinatari} </b>
                        </div>
                    }
                </div>
                {
                    windowWidth > 1200 &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px', flex: 1 }}>
                        <button
                            className="button"
                            onClick={() => scaricaAllegati(portaleCtx.testata.Id)}
                            style={{ maxHeight: '45px' }}
                        >
                            Scarica Allegati<IoAttach className="text-icon-right" />
                        </button>
                    </div>
                }
            </div>
            <div className="chat-container">
                <div className="messages-container" style={{ flex: 1, maxHeight: windowHeight * 0.52, minHeight: windowHeight * 0.45 }}>
                    {messaggi.map((msg, index) => (
                        <div
                            className="message"
                            key={index}
                            style={{
                                alignItems: msg.CodMittente == authCtx.impostazioni.CodUtente ? 'flex-start' : 'flex-start',
                                alignSelf: msg.CodMittente == authCtx.impostazioni.CodUtente ? 'flex-end' : 'flex-start',
                                backgroundColor: msg.CodMittente == authCtx.impostazioni.CodUtente ? '#d6ecff' : '#fff',
                            }}
                        >
                            {
                                portaleCtx.testata.NomiDestinatari != undefined &&
                                portaleCtx.testata.NomiDestinatari.includes(',') && msg.CodMittente != authCtx.impostazioni.CodUtente && (
                                    <div className='sender-name-text'>
                                        {renderSenderName(msg.CodMittente, portaleCtx.utenti)}
                                    </div>
                                )}
                            <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                {renderTextWithLineBreaks(msg.Messaggio)}
                            </div>
                            <div className="timestamp">
                                {msg.Data + " " + msg.Ora}
                            </div>
                            {
                                msg.Allegato && (
                                    <div style={{ marginTop: '10px' }}>
                                        {isImage(msg.Allegato) ? (
                                            <img
                                                src={msg.Allegato}
                                                alt="Allegato"
                                                style={{ width: '150px', height: 'auto', cursor: 'pointer' }}
                                                onClick={() => window.open(msg.Allegato, '_blank', 'noopener,noreferrer')}
                                            />
                                        ) : (
                                            <button className='icon-button' onClick={() => window.open(msg.Allegato, '_blank', 'noopener,noreferrer')}>
                                                {getFileNameFromUrl(msg.Allegato)}
                                                <IoAttach className="icon" />
                                            </button>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
                {
                    !flgVistati && //L'INVIO MESSAGGI E' DISATTIVATO SUI "CHIUSI"
                    <div className="input-group" id="input-group-modal-chat" style={{ alignItems: 'flex-start' }}>
                        <textarea
                            value={newMessage}
                            rows={4}
                            onChange={(e) => setNewMessage(e.target.value)}
                            className='valori'
                            id='textarea-chat'
                            style={{ flex: 1 }}
                            placeholder="Scrivi un messaggio..."
                        />
                        <div>
                            <button onClick={handleSendMessage} className={newMessage.trim() !== '' ? 'icon-button' : 'icon-button disabled'}
                                style={{ marginLeft: '10px', marginTop: '10px', height: '40px', paddingLeft: '8px', paddingRight: '8px' }}
                            >
                                <IoSendSharp className="icon" />
                            </button>
                            <button onClick={handleScegliAllegato} className={'icon-button'}
                                style={{ marginLeft: '10px', marginTop: '10px', height: '40px', paddingLeft: '8px', paddingRight: '8px' }}
                            >
                                <IoAttach className="icon" />
                            </button>
                            <input
                                type="file"
                                ref={fileInputRef}
                                multiple
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div>
                    </div>
                }
                {
                    selectedFiles != null &&
                    <div className="info-modal-title" style={{ fontWeight: 'inherit', marginBottom: '7px', justifyContent: 'flex-start' }}>
                        <IoAttachOutline className="icon" /><b style={{ marginLeft: '5px', marginRight: '5px' }} >Allegato inserito </b>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default ModalChat;
