import { createContext, useState } from "react";

export const PortaleContext = createContext({
    activeTab: 1,
    setActiveTabCtx: () => { },
    utenti: [],
    setUtentiCtx: () => { },
    clienti: [],
    setClientiCtx: () => { },
    tipiRichiesta: [],
    setTipiRichiestaCtx: () => { },
    tipoRichiestaCorrente: [],
    setTipoRichiestaCorrenteCtx: () => { },
    vistati: [],
    setVistatiCtx: () => { },
    nonVistati: [],
    setNonVistatiCtx: () => { },
    numNonLetti: 0,
    setNumNonLettiCtx: () => {},
    idTaskCorrente: 0,
    setIdTaskCorrenteCtx: () => { },
    messaggi: [],
    setMessaggiCtx: () => { },
    testata: [],
    setTestataCtx: () => { },
});

function PortaleContextProvider({ children }) {
    const [activeTab, setActiveTab] = useState(1);
    const [utenti, setUtenti] = useState([]);
    const [clienti, setClienti] = useState([]);
    const [tipiRichiesta, setTipiRichiesta] = useState([]);
    const [tipoRichiestaCorrente, setTipoRichiestaCorrente] = useState([]);
    const [vistati, setVistati] = useState([]);
    const [nonVistati, setNonVistati] = useState([]);
    const [numNonLetti, setNumNonLetti] = useState(0);
    const [idTaskCorrente, setIdTaskCorrente] = useState(0);
    const [messaggi, setMessaggi] = useState([]);
    const [testata, setTestata] = useState([]);

    function setActiveTabCtx(tab) {
        setActiveTab(tab);
    }

    function setUtentiCtx(utenti) {
        setUtenti(utenti);
    }

    function setClientiCtx(utenti) {
        setClienti(utenti);
    }

    function setTipiRichiestaCtx(tipiRichiesta) {
        setTipiRichiesta(tipiRichiesta);
    }

    function setTipoRichiestaCorrenteCtx(tipo) {
        setTipoRichiestaCorrente(tipo);
    }

    function setVistatiCtx(arr) {
        setVistati(arr);
    }

    function setNonVistatiCtx(arr) {
        setNonVistati(arr);
    }

    function setNumNonLettiCtx(num) {
        setNumNonLetti(num);
    }

    function setIdTaskCorrenteCtx(id) {
        setIdTaskCorrente(id);
    }

    function setMessaggiCtx(arr) {
        setMessaggi(arr);
    }

    function setTestataCtx(arr) {
        setTestata(arr);
    }

    const value = {
        activeTab: activeTab,
        setActiveTabCtx: setActiveTabCtx,
        utenti: utenti,
        setUtentiCtx: setUtentiCtx,
        clienti: clienti,
        setClientiCtx: setClientiCtx,
        tipiRichiesta: tipiRichiesta,
        setTipiRichiestaCtx: setTipiRichiestaCtx,
        tipoRichiestaCorrente: tipoRichiestaCorrente,
        setTipoRichiestaCorrenteCtx: setTipoRichiestaCorrenteCtx,
        vistati: vistati,
        setVistatiCtx: setVistatiCtx,
        nonVistati: nonVistati,
        setNonVistatiCtx: setNonVistatiCtx,
        numNonLetti: numNonLetti,
        setNumNonLettiCtx: setNumNonLettiCtx,
        idTaskCorrente: idTaskCorrente,
        setIdTaskCorrenteCtx: setIdTaskCorrenteCtx,
        messaggi: messaggi,
        setMessaggiCtx: setMessaggiCtx,
        testata: testata,
        setTestataCtx: setTestataCtx,
    }

    return <PortaleContext.Provider value={value} >{children}</PortaleContext.Provider>
}

export default PortaleContextProvider;