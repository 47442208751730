import '../Portale.css'; // Importa il file CSS per lo stile

import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import ModalLoading from './modals/ModalLoading';
import { ColumnFilter } from './ColumnFilter';
import FilterSection from './FilterSection';
import { PortaleContext } from '../store/portale-context';
import { AuthContext } from '../store/auth-context';
import useViewportHeight from './utils/useViewportHeight';

// modal
import ModalError from './modals/ModalError';
import ModalErrorToken from './modals/ModalErrorToken';
import ModalImpostazioni from './modals/ModalImpostazioni';
import ModalLogout from './modals/ModalLogout';
import ModalGestioneTipiRichiesta from './modals/ModalGestioneTipiRichiesta';
import ModalNuovoTask from './modals/ModalNuovoTask';
import ModalChat from './modals/ModalChat';
import ModalVistato from './modals/ModalVistato';

// tabelle
import NonVistatiTable from './tables/NonVistatiTable';
import VistatiTable from './tables/VistatiTable';

import { IoAddCircleSharp, IoAttach, IoCameraOutline, IoCaretDown, IoCaretUp, IoCloudUpload, IoList, IoPeople, IoPeopleOutline, IoSettingsSharp, IoSyncCircle, IoSyncCircleOutline } from 'react-icons/io5';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MainPage() {

    const authCtx = useContext(AuthContext);
    const portaleCtx = useContext(PortaleContext);
    const vh = useViewportHeight();

    const [flgVistati, setFlgVistati] = useState(false);
    const [flgNonLetti, setFlgNonLetti] = useState(false);
    const [flgFirstLoad, setFlgFirstLoad] = useState(true);
    const [contatoreRichieste, setContatoreRichieste] = useState([0, 0, 0]);

    const [modalLoading, setModalLoading] = useState(false);
    const [modalErrorToken, setModalErrorToken] = useState(false);
    const [modalError, setModalError] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [modalImpostazioni, setModalImpostazioni] = useState(false);
    const [modalLogout, setModalLogout] = useState(false);
    const [modalTipiRichiesta, setModalTipiRichiesta] = useState(false);
    const [modalNuovoTask, setModalNuovoTask] = useState(false);
    const [modalChat, setModalChat] = useState(false);
    const [modalVistato, setModalVistato] = useState(false);

    const [dataDal, setDataDal] = useState('');
    const [dataAl, setDataAl] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);

    const [collapsed, setCollapsed] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    //funzioni grafiche
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        // Clean up: rimuovi l'event listener quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, []);

    const getCurrentDate = () => {
        const date = new Date(); // creates a new date object representing the current date and time

        let day = date.getDate(); // get the day as a number (1-31)
        let month = date.getMonth() + 1; // get the month as a number (0-11) so add 1 to make it (1-12)
        let year = date.getFullYear(); // get the year as four digits (yyyy)

        // Ensure day and month are always two digits by adding leading zeros if needed
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        return `${year}-${month}-${day}`; // returns the date in DD-mm-yyyy format
    }

    function getDefaultFromDate() {
        let today = new Date();
        today.setMonth(today.getMonth() - 1);
        return today.toISOString().split('T')[0];
    };

    //effect operativi
    useLayoutEffect(() => {
        setDataAl(getCurrentDate());
        setDataDal(getDefaultFromDate());
    }, []);

    useEffect(() => {
        getTask();
    }, [portaleCtx.activeTab]);

    useEffect(() => {
        getTask();
    }, [modalChat]);

    const aggiornaDatiPortale = (dati) => {

        setContatoreRichieste(dati.conteggi);
        portaleCtx.setVistatiCtx(dati.vistati);
        portaleCtx.setNonVistatiCtx(dati.nonVistati);
        portaleCtx.setNumNonLettiCtx(dati.nonLetti);

        portaleCtx.setTipiRichiestaCtx(dati.tipiRichiesta);
        portaleCtx.setUtentiCtx(dati.utenti);
        portaleCtx.setClientiCtx(dati.clienti);
    }

    //caricamento dati necessari
    const getTask = () => {

        setModalLoading(true);

        let tipo = portaleCtx.activeTab;
        //visualizzazione "attività richieste" di default per anselmo
        if (authCtx.impostazioni.username == 'ANSELMO' && flgFirstLoad) {
            tipo = 2;
            portaleCtx.setActiveTabCtx(tipo);
            setFlgFirstLoad(false);
        }

        var data = {
            'op': 'getTask',
            'tipo': tipo,
            'dataDal': dataDal == '' ? getDefaultFromDate() : dataDal,
            'dataAl': dataAl == '' ? getCurrentDate() : dataAl,
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura degli ordini' });
                        }
                        return;
                    }
                    aggiornaDatiPortale(responseJson);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura degli ordini' })
                })
        );
    };

    const columnsVistati = [
        portaleCtx.activeTab === 1 || portaleCtx.activeTab === 3 ? { Header: 'Mittente', accessor: 'NomeMittente', Filter: ColumnFilter } : null,
        portaleCtx.activeTab === 2 || portaleCtx.activeTab === 3 ? { Header: 'Destinatario', accessor: 'NomiDestinatari', Filter: ColumnFilter } : null,
        { Header: 'Cliente\\Fornitore', accessor: 'RagSocCliente', Filter: ColumnFilter },
        { Header: 'Tipo richiesta', accessor: 'DesTipoRichiesta', Filter: ColumnFilter },
        { Header: 'Commessa', accessor: 'Commessa', Filter: ColumnFilter },
        {
            Header: 'Data apertura', accessor: 'DataApertura', Filter: ColumnFilter,
            sortType: (rowA, rowB, columnId) => {
                // Confronta le date convertendole in oggetti Date
                const dateA = new Date(rowA.values[columnId]);
                const dateB = new Date(rowB.values[columnId]);

                return dateA - dateB;
            },
        },
        { Header: 'Ora apertura', accessor: 'OraApertura', Filter: ColumnFilter },
        { Header: 'Scadenza', accessor: 'DataScadenza', Filter: ColumnFilter },
        { Header: 'Messaggio Iniziale', accessor: 'MessaggioIniziale', Filter: ColumnFilter },
    ].filter(column => column !== null);

    const columnsNonVistati = [
        portaleCtx.activeTab === 1 || portaleCtx.activeTab === 3 ? { Header: 'Mittente', accessor: 'NomeMittente', Filter: ColumnFilter } : null,
        portaleCtx.activeTab === 2 || portaleCtx.activeTab === 3 ? { Header: 'Destinatario', accessor: 'NomiDestinatari', Filter: ColumnFilter } : null,
        { Header: 'Cliente\\Fornitore', accessor: 'RagSocCliente', Filter: ColumnFilter },
        { Header: 'Tipo richiesta', accessor: 'DesTipoRichiesta', Filter: ColumnFilter },
        { Header: 'Commessa', accessor: 'Commessa', Filter: ColumnFilter },
        {
            Header: 'Data apertura', accessor: 'DataApertura', Filter: ColumnFilter,
            sortType: (rowA, rowB, columnId) => {
                // Confronta le date convertendole in oggetti Date
                const dateA = new Date(rowA.values[columnId]);
                const dateB = new Date(rowB.values[columnId]);

                return dateA - dateB;
            },
        },
        { Header: 'Ora apertura', accessor: 'OraApertura', Filter: ColumnFilter },
        { Header: 'Scadenza', accessor: 'DataScadenza', Filter: ColumnFilter },
        { Header: 'Messaggio Iniziale', accessor: 'MessaggioIniziale', Filter: ColumnFilter, width: 500 },
    ].filter(column => column !== null);

    const chiudiModalImpostazioni = () => {
        setModalImpostazioni(false);
    }

    const apriModalLogout = () => {
        setModalImpostazioni(false);
        setModalLogout(true);
    }

    const chiudiModalLogout = (logout) => {
        setModalLogout(false);
        if (logout) authCtx.logout();
        else setModalImpostazioni(true);
    }

    const apriModalTipiRichiesta = () => {
        setModalImpostazioni(false);
        setModalTipiRichiesta(true);
    }

    const chiudiModalTipiRichiesta = () => {
        setModalTipiRichiesta(false);
        setModalImpostazioni(true);
    }

    const apriModalVistato = (riga) => {
        portaleCtx.setIdTaskCorrenteCtx(riga.Id);
        setModalVistato(true);
    }

    const salvaNuovoTask = async (dati) => {
        setModalNuovoTask(false);
        setModalLoading(true);

        console.log(dati);

        let commessa = '';
        if (dati.commessa != null && dati.commessa.value != undefined) commessa = dati.commessa.value;
        let dataScadenza = dati.dataScadenza || '';

        // Carica tutti i file con handleUploadFiles
        let allegatiCaricati = [];
        if (dati.allegati && dati.allegati.length > 0) {
            allegatiCaricati = await handleUploadFiles(dati.allegati);

            if (allegatiCaricati === false) {
                toast.error('Errore nel caricamento degli allegati!', {
                    position: 'top-center',
                    autoClose: 3000,
                });
                return;
            }
        }

        const clienteDefinitivo = dati.cliente ? dati.cliente.value : 0;

        const data = {
            op: 'salvaNuovoTask',
            destinatari: dati.destinatari.map(d => d.value),
            cliente: clienteDefinitivo,
            clienteDigitato: dati.clienteDigitato.toUpperCase(),
            commessa: commessa,
            commessaDigitata: dati.commessaDigitata.toUpperCase(),
            tipoRichiesta: dati.tipoRichiesta.value,
            dataScadenza: dataScadenza,
            messaggio: dati.messaggio,
            tipo: portaleCtx.activeTab,
            dataDal: dataDal == '' ? getDefaultFromDate() : dataDal,
            dataAl: dataAl == '' ? getCurrentDate() : dataAl,
            allegati: allegatiCaricati, // Elenco dei file caricati con note
        };

        return fetch(authCtx.serverUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(responseJson => {
                setModalLoading(false);
                if (!responseJson.status) {
                    if (responseJson.errorToken) {
                        setModalErrorToken(true);
                    } else {
                        setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel salvataggio del messaggio' });
                    }
                    return;
                }
                aggiornaDatiPortale(responseJson);
                toast.success('Attività inserita correttamente!', {
                    position: "top-center",
                    autoClose: 3000, // Chiude automaticamente dopo 3 secondi
                });
            })
            .catch(error => {
                setModalLoading(false);
                console.error(error);
                setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel salvataggio del messaggio' });
            });
    };

    const impostaVistato = () => {

        setModalVistato(false);
        setModalLoading(true);
        var data = {
            'op': 'impostaVistato',
            'idTask': portaleCtx.idTaskCorrente,
            'tipo': portaleCtx.activeTab,
            'dataDal': dataDal == '' ? getDefaultFromDate() : dataDal,
            'dataAl': dataAl == '' ? getCurrentDate() : dataAl,
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: "Errore nell'aggiornamento dello stato" });
                        }
                        return;
                    }
                    aggiornaDatiPortale(responseJson);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: "Errore nell'aggiornamento dello stato" })
                })
        );
    };

    const getDatiChat = (riga) => {

        portaleCtx.setIdTaskCorrenteCtx(riga.Id);

        setModalLoading(true);
        var data = {
            'op': 'getDatiChat',
            'idTask': riga.Id
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura dei messaggi' });
                        }
                        return;
                    }
                    portaleCtx.setMessaggiCtx(responseJson.messaggi);
                    portaleCtx.setTestataCtx(responseJson.testata);
                    setModalChat(true);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura dai messaggi' })
                })
        );
    }

    const inviaMessaggio = async (msg, files) => {

        msg = msg.replace(/\r\n|\r|\n/g, '\r\n');

        setModalLoading(true);

        let nomeFile = '';
        if (files != null) {
            nomeFile = await handleUploadSingleFile(files);
        }

        var data = {
            'op': 'inviaMessaggio',
            'messaggio': msg,
            'idTask': portaleCtx.idTaskCorrente,
            'tipo': portaleCtx.activeTab,
            'nomeFile': nomeFile,
        };

        return (
            fetch(authCtx.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setModalLoading(false);
                    if (responseJson.status === false) {
                        if (responseJson.errorToken === true) {
                            setModalErrorToken(true);
                        } else {
                            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nella lettura dei messaggi' });
                        }
                        return;
                    }
                    portaleCtx.setMessaggiCtx(responseJson.messaggi);
                    return;
                })
                .catch(error => {
                    setModalLoading(false);
                    console.log(error);
                    setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel salvataggio del messaggio' })
                })
        );
    };

    const handleUploadFiles = async (files) => {
        const formData = new FormData();
    
        // Aggiunge i file e le note al FormData
        files.forEach((file, index) => {
            formData.append('files[]', file.file); // File allegato
            formData.append('notes[]', file.nota || ''); // Nota associata
        });
    
        try {
            const response = await fetch(authCtx.uploadUrl, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                setModalError({
                    flgModal: true,
                    titolo: 'ATTENZIONE',
                    testo: 'Errore nel caricamento degli allegati',
                });
                return false;
            }
    
            // Riceve la risposta dal backend
            const data = await response.json();
            return data.map((file, index) => ({
                nome: file.file, // Nome del file restituito dal backend
                nota: files[index]?.nota || '', // Nota associata
            }));
        } catch (error) {
            console.error('Errore nel caricamento degli allegati:', error);
            return false;
        }
    };

    const handleUploadSingleFile = async (files) => {
        const formData = new FormData();
        formData.append('files[]', files[0]);

        try {
            const response = await fetch(authCtx.uploadSingleUrl, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Errore nel caricamento allegato' })
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const scaricaAllegati = async (idTestata) => {
        try {
            const response = await fetch(authCtx.serverUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    op: "scaricaAllegati",
                    IdTestata: idTestata,
                }),
            });
    
            if (!response.ok) {
                throw new Error("Errore nel download del file ZIP");
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `allegati_testata_${idTestata}.zip`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Errore nel download degli allegati:", error);
        }
    };

    const handleClickNonLetti = () => {
        if (!flgNonLetti) {
            //se sto visualizzando tutto filtro i non letti
            const filteredNonVistati = portaleCtx.nonVistati.filter(item => item.NumNonLetti > 0);
            portaleCtx.setNonVistatiCtx(filteredNonVistati);
            setFlgNonLetti(true);
        } else {
            //se sto visualizzando i non letti rieseguo fetch
            getTask();
            setFlgNonLetti(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]); // Settiamo il file selezionato
    };

    const handleScegliAllegatoCSV = () => {
        document.getElementById('fileInput').click();
    };

    const handleUploadFilesCSV = async (tipoArchivio) => {
        if (!selectedFile) {
            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: 'Seleziona un file CSV prima di continuare' });
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('tipoArchivio', tipoArchivio);  // Passiamo il tipo di archivio

        setModalLoading(true);

        try {
            const response = await fetch(authCtx.uploadUrlCSV, {
                method: 'POST',
                body: formData,
                credentials: 'include',  // Includi i cookie per l'autenticazione
            });

            if (!response.ok) {
                throw new Error('Errore nel caricamento del file');
            }

            const data = await response.json();
            if (data.status === 'success') {
                window.open('https://portale-web-messaggistica.csd-hosting.it/server/cronjob/day.php');
                setSelectedFile(null);
                getTask();
            } else {
                throw new Error('Errore nel caricamento del file');
            }
        } catch (error) {
            console.error('Error:', error);
            setModalError({ flgModal: true, titolo: 'ATTENZIONE', testo: error.message });
        } finally {
            setModalLoading(false);
        }
    };


    return (
        <div className='portale-container' style={{ height: `${vh}px` }}>
            <ToastContainer /*ATTENZIONE: per richiamarlo da altri componenti importare SOLO funzione toast dalla libreiria*/ />
            {
                authCtx.impostazioni.tipoUtente == 0 ?
                    <>
                        <div className="dropdown-menu">
                            <div className="dropdown-tabs">
                                <div className='tab'>
                                    <div>
                                        {portaleCtx.activeTab === 1 ? 'LE MIE ATTIVITÀ ' : portaleCtx.activeTab === 2 ? 'ATTIVITÀ RICHIESTE ' : 'TUTTE '}
                                    </div>
                                </div>
                                <div className="menu-button" onClick={toggleCollapse}>
                                    Menu {collapsed ? <IoCaretUp className="text-icon-right" /> : <IoCaretDown className="text-icon-right" />}
                                </div>
                            </div>
                            {collapsed && (
                                <>
                                    <div className="dropdown-overlay" onClick={toggleCollapse}>
                                        <div className="dropdown-content">
                                            <div className={`dropdown-tab ${portaleCtx.activeTab === 1 ? 'active' : ''}`}
                                                style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '20px', marginRight: '20px' }}
                                                onClick={() => { portaleCtx.setActiveTabCtx(1); toggleCollapse(); }}>
                                                <div>LE MIE ATTIVITÀ </div>
                                            </div>
                                            <div className={`dropdown-tab ${portaleCtx.activeTab === 2 ? 'active' : ''}`}
                                                style={{ marginBottom: '10px', marginLeft: '20px', marginRight: '20px' }}
                                                onClick={() => { portaleCtx.setActiveTabCtx(2); toggleCollapse(); }}>
                                                <div>ATTIVITÀ RICHIESTE </div>
                                            </div>
                                            {
                                                false &&
                                                <div className={`dropdown-tab ${portaleCtx.activeTab === 3 ? 'active' : ''}`}
                                                    style={{ marginBottom: '10px', marginLeft: '20px', marginRight: '20px' }}
                                                    onClick={() => { portaleCtx.setActiveTabCtx(3); toggleCollapse(); }}>
                                                    <div>TUTTE <span className={`${portaleCtx.activeTab === 3 ? 'dropdown-bullet active' : 'dropdown-bullet'}`}>
                                                        {contatoreRichieste[2]}</span></div>
                                                </div>
                                            }

                                            <div className="dropdown-icons-container">
                                                <div className="dropdown-icon" onClick={() => { setModalNuovoTask(true); toggleCollapse(); }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><IoAddCircleSharp style={{ height: '50px', width: '50px', color: '#1c8d42' }} /></div>
                                                </div>
                                                <div className="dropdown-icon" onClick={() => { setModalImpostazioni(true); toggleCollapse(); }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><IoSettingsSharp style={{ height: '50px', width: '50px', color: '#de7504' }} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="tabs">
                            <div className={`tab ${portaleCtx.activeTab === 1 ? 'active' : ''}`} onClick={() => portaleCtx.setActiveTabCtx(1)}>
                                <div>LE MIE ATTIVITÀ </div>
                            </div>
                            <div className={`tab ${portaleCtx.activeTab === 2 ? 'active' : ''}`} onClick={() => portaleCtx.setActiveTabCtx(2)} style={{ marginLeft: '30px', marginRight: '30px' }}>
                                <div>ATTIVITÀ RICHIESTE</div>
                            </div>
                            {
                                false &&
                                <div className={`tab ${portaleCtx.activeTab === 3 ? 'active' : ''}`} onClick={() => portaleCtx.setActiveTabCtx(3)}>
                                    <div>TUTTE <span className={`${portaleCtx.activeTab === 3 ? 'bullet active' : 'bullet'}`}>{contatoreRichieste[2]}</span></div>
                                </div>
                            }
                            <IoAddCircleSharp className={`tab tab-small green`} style={{ marginLeft: '20px' }} onClick={() => setModalNuovoTask(true)} />
                            <IoSettingsSharp className={`tab tab-small orange`} style={{ marginLeft: '20px' }} onClick={() => setModalImpostazioni(true)} />
                            {
                                true &&
                                <>
                                    <IoAttach
                                        className={`tab tab-small blue`}
                                        style={{ marginLeft: '20px', cursor: 'pointer' }}
                                        onClick={handleScegliAllegatoCSV}
                                    />
                                    <div style={{ marginBottom: '20px' }}>
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            id="fileInput"
                                        />
                                    </div>
                                    <IoPeople
                                        className={`tab tab-small ${!selectedFile ? 'disabled' : ' blue'}`}
                                        style={{ marginLeft: '20px', cursor: 'pointer' }}
                                        onClick={() => selectedFile && handleUploadFilesCSV('clienti')}
                                        title='Clienti'
                                    />
                                    <IoList
                                        className={`tab tab-small ${!selectedFile ? 'disabled' : ' blue'}`}
                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                        onClick={() => selectedFile && handleUploadFilesCSV('commesse')}
                                        title='Commesse'
                                    />
                                    <IoPeopleOutline
                                        className={`tab tab-small ${!selectedFile ? 'disabled' : ' blue'}`}
                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                        onClick={() => selectedFile && handleUploadFilesCSV('fornitori')}
                                        title='Fornitori'
                                    />
                                </>
                            }
                            {
                                false &&
                                <IoCloudUpload className={`tab tab-small yellow`} style={{ marginLeft: '10px' }}
                                    onClick={() => { window.open('https://portale-web-messaggistica.csd-hosting.it/server/cronjob/day.php', '_blank', 'noopener,noreferrer') }} />
                            }
                        </div>

                        <div className="sub-tabs"
                            style={windowWidth > 1000 ? { display: 'flex', flexDirection: 'row', padding: '0px', alignItems: 'center' }
                                : { display: 'flex', flexDirection: 'column', padding: '0px', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', marginBottom: '12px' }}>
                                <div className="nav-item" style={!flgVistati && windowWidth > 700 ? { border: '3px solid #0c8133' } : { border: '2px solid transparent' }} onClick={() => setFlgVistati(false)}>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#0c8133' }}>
                                        <span className={`${!flgVistati ? 'bullet active' : 'bullet'} sub-bullet`} style={{ backgroundColor: '#0c8133', marginRight: '10px' }}>
                                            {portaleCtx.nonVistati.length}
                                        </span>
                                        DA SVOLGERE
                                        <span className="badge-non-letti" style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '30px' }} onClick={handleClickNonLetti}>
                                            {portaleCtx.numNonLetti} {windowWidth > 800 ? "messaggi" : " "} non letti
                                        </span>
                                    </div>
                                </div>
                                {
                                    windowWidth > 700 &&
                                    <div className="nav-item" style={flgVistati ? { border: '2px solid #555', maxWidth: '100px' } : { border: '2px solid transparent', maxWidth: '100px' }} onClick={() => setFlgVistati(true)}>
                                        <div style={{ display: 'flex', alignItems: 'center', color: '#555' }}>
                                            CHIUSI
                                            {
                                                false && <span className={`${flgVistati ? 'bullet active' : 'bullet'} sub-bullet`} style={{ backgroundColor: '#555' }}>
                                                    {portaleCtx.vistati.length}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '12px', marginLeft: '15px' }}>
                                <div className='filtro-date-container'>
                                    Dal
                                    <input
                                        className='filtro-date'
                                        type="date"
                                        placeholder=""
                                        value={dataDal}
                                        onChange={(e) => setDataDal(e.target.value)}
                                    />
                                </div>
                                <div className='filtro-date-container'>
                                    Al
                                    <input
                                        className='filtro-date'
                                        type="date"
                                        placeholder=""
                                        value={dataAl}
                                        onChange={(e) => setDataAl(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <IoSyncCircle className={`tab tab-small blue`} onClick={getTask} />
                                </div>
                            </div>
                        </div>
                        {
                            !flgVistati ?
                                <NonVistatiTable columns={columnsNonVistati} data={portaleCtx.nonVistati} getDatiChat={getDatiChat} apriModalVistato={apriModalVistato} />
                                :
                                <VistatiTable columns={columnsVistati} data={portaleCtx.vistati} getDatiChat={getDatiChat} />
                        }
                    </>
                    :
                    <div className="portale-login" style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                            <IoSettingsSharp className={`tab tab-small orange`} style={{ marginRight: '25px' }} onClick={() => setModalImpostazioni(true)} />
                        </div>
                        <div className='portale-container-login login-page'>

                            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                                <img alt='Immagine non trovata' src={"/images/AZ-Logo.svg"} className="logo-login" style={{ maxWidth: '200px', height: 'auto' }} />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', margin: '45px' }}>
                                <button
                                    className="button"
                                    style={{ padding: "15px", borderRadius: '8px', fontSize: '18px', minWidth: '250px' }}
                                    onClick={() => {
                                        setModalNuovoTask(true);
                                    }}
                                >
                                    Carica foto <IoCameraOutline className="text-icon-right" />
                                </button>
                            </div>
                        </div>
                    </div>
            }

            <ModalError setModalError={setModalError} modalState={modalError} />
            <ModalErrorToken visible={modalErrorToken} />
            <ModalLoading visible={modalLoading} color="#063970" />
            <ModalLogout visible={modalLogout} chiudiModalLogout={chiudiModalLogout} />
            <ModalImpostazioni chiudiModalImpostazioni={chiudiModalImpostazioni} visible={modalImpostazioni} apriModalLogout={apriModalLogout} apriModalTipiRichiesta={apriModalTipiRichiesta} />
            <ModalNuovoTask setModalNuovoTask={setModalNuovoTask} visible={modalNuovoTask} salvaNuovoTask={salvaNuovoTask} />
            <ModalChat visible={modalChat} setModalChat={setModalChat} messaggi={portaleCtx.messaggi} inviaMessaggio={inviaMessaggio} 
            setModalError={setModalError} flgVistati={flgVistati} scaricaAllegati={scaricaAllegati} />
            <ModalVistato setModalVistato={setModalVistato} visible={modalVistato} impostaVistato={impostaVistato} />
            <ModalGestioneTipiRichiesta chiudiModalTipiRichiesta={chiudiModalTipiRichiesta} visible={modalTipiRichiesta} aggiornaDatiPortale={aggiornaDatiPortale}
                dataDal={dataDal} dataAl={dataDal} getDefaultFromDate={getDefaultFromDate} getCurrentDate={getCurrentDate} />


        </div >
    );
}

export default MainPage;
