import { createContext, useState } from "react";

export const AuthContext = createContext({
    serverUrl: '',
    uploadUrl: '',
    uploadSingleUrl: '',
    uploadUrlCSV: '',
    isAuthenticated: false,
    login: () => { },
    logout: () => { },
    impostazioni: [],
    setImpostazioniCtx: () => { },
});

function AuthContextProvider({ children }) {
    
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [impostazioni, setImpostazioni] = useState([]);

    function login() {
        setIsAuthenticated(true);
    }

    function logout() {
        var data = {
            'op': 'logout',
        };
        return (
            fetch(value.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setIsAuthenticated(false);
                    return;
                })
                .catch(error => {
                    console.log(error);
                })
        );
    }

    function setImpostazioniCtx(imp) {
        setImpostazioni(imp);
    }

    const value = {
        serverUrl: 'https://portale-web-messaggistica.csd-hosting.it/server/index.php',
        uploadUrl: 'https://portale-web-messaggistica.csd-hosting.it/server/uploadFiles.php',
        uploadSingleUrl: 'https://portale-web-messaggistica.csd-hosting.it/server/upload.php',
        uploadUrlCSV: 'https://portale-web-messaggistica.csd-hosting.it/server/uploadCSV.php',
        isAuthenticated: isAuthenticated,
        login: login,
        logout: logout,
        impostazioni: impostazioni,
        setImpostazioniCtx: setImpostazioniCtx
    }

    return <AuthContext.Provider value={value} >{children}</AuthContext.Provider>
}

export default AuthContextProvider;