import '../../Portale.css'; // Importa il file CSS per lo stile

import React, { useState, useEffect, useContext, useRef } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import { IoArrowUndoSharp, IoAttach, IoCheckmarkCircle, IoClose, IoList, IoPencil, IoPencilSharp, IoWarning } from "react-icons/io5";
import { AuthContext } from '../../store/auth-context';
import { PortaleContext } from '../../store/portale-context';
import { FaKeyboard, FaPencil } from 'react-icons/fa6';
import { toast } from 'react-toastify';

function ModalNuovoTask({ setModalNuovoTask, visible, salvaNuovoTask }) {

    const authCtx = useContext(AuthContext);
    const portaleCtx = useContext(PortaleContext);
    const [commesse, setCommesse] = useState([]);
    const [flgClienteDigitato, setFlgClienteDigitato] = useState(false);
    const [flgCommessaDigitata, setFlgCommessaDigitata] = useState(false);
    const [flgNotaPerAllegato, setFlgNotaPerAllegato] = useState(false);
    const [dati, setDati] = useState({
        destinatari: null,
        cliente: null,
        commessa: null,
        tipoRichiesta: null,
        dataScadenza: null,
        messaggio: '',
        allegato: null
    });

    const [allegati, setAllegati] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        setDati({
            destinatari: null,
            cliente: null,
            clienteDigitato: '',
            commessa: null,
            commessaDigitata: '',
            tipoRichiesta: null,
            dataScadenza: getCurrentDate(),
            messaggio: '',
            allegato: null
        });
        setCommesse([]);
        setAllegati([]);
        setFlgNotaPerAllegato(false);
    }, [visible]);

    const getCurrentDate = () => {

        const date = new Date(); // creates a new date object representing the current date and time

        let day = date.getDate(); // get the day as a number (1-31)
        let month = date.getMonth() + 1; // get the month as a number (0-11) so add 1 to make it (1-12)
        let year = date.getFullYear(); // get the year as four digits (yyyy)

        // Ensure day and month are always two digits by adding leading zeros if needed
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        return `${year}-${month}-${day}`; // returns the date in DD-mm-yyyy format
    }

    const checkDatiInseriti = () => {
        let datiCorrenti = dati;
        if (authCtx.impostazioni.tipoUtente != 0) {
            datiCorrenti.destinatari = [{ CodCliFor: 2, Nome: 'DANIELA', IndirEmail: 'daniela@impiantiaz.it', value: 2, label: 'DANIELA' }];
            datiCorrenti.tipoRichiesta = { Codice: 17, Descrizione: 'CARICAMENTO FOTO', value: 17, label: 'CARICAMENTO FOTO' };
        }

        if (!datiCorrenti.destinatari) {
            toast.error('Selezionare almeno un destinatario!', {
                position: "top-center",
                autoClose: 3000, // Chiude automaticamente dopo 3 secondi
            });
            return;
        }

        if (flgClienteDigitato) {
            if (!datiCorrenti.clienteDigitato) {
                toast.error('Digitare il cliente!', {
                    position: "top-center",
                    autoClose: 3000, // Chiude automaticamente dopo 3 secondi
                });
                return;
            }
        } else {
            if (!datiCorrenti.cliente) {
                toast.error('Selezionare il cliente!', {
                    position: "top-center",
                    autoClose: 3000, // Chiude automaticamente dopo 3 secondi
                });
                return;
            }
        }

        if (!flgCommessaDigitata) datiCorrenti.commessaDigitata = ''; //se ho messo e poi tolto commessa digitata azzero il campo così non lo salva nel DB

        if (!datiCorrenti.tipoRichiesta) {
            toast.error('Selezionare il tipo di richiesta!', {
                position: "top-center",
                autoClose: 3000, // Chiude automaticamente dopo 3 secondi
            });
            return;
        }

        if (!datiCorrenti.messaggio && !flgNotaPerAllegato) {
            toast.error('Digitare il messaggio!', {
                position: "top-center",
                autoClose: 3000, // Chiude automaticamente dopo 3 secondi
            });
            return;
        }

        if (allegati.length === 0 && authCtx.impostazioni.tipoUtente == 1) {
            toast.error('Caricare almeno un allegato!', {
                position: "top-center",
                autoClose: 3000, // Chiude automaticamente dopo 3 secondi
            });
            return;
        }

        // Invio i dati formattati
        console.log("go")
        salvaNuovoTask({
            ...datiCorrenti,
            allegati: allegati.map(allegato => ({
                file: allegato.file,
                nota: allegato.nota || '',
            })),
        });

        setModalNuovoTask(false);
    };


    const impostaCliente = (e) => {
        let codCliente = e.target.value;
        setDati({ ...dati, cliente: codCliente, commessa: 'empty' });
        for (let i = 0; i < portaleCtx.clienti.length; i++) {
            if (portaleCtx.clienti[i].CodCliFor == codCliente) {
                setCommesse(portaleCtx.clienti[i].Commesse);
            }
        }
    }

    const handleDestinatariChange = (selectedOption) => {
        setDati({ ...dati, destinatari: selectedOption });
    };

    const handleClienteChange = (selectedOption) => {
        setDati({ ...dati, cliente: selectedOption, commessa: '' });
        setCommesse(selectedOption.Commesse);
    };

    const handleCommessaChange = (selectedOption) => {
        setDati({ ...dati, commessa: selectedOption });
    };

    const handleTipoRichiestaChange = (selectedOption) => {
        setDati({ ...dati, tipoRichiesta: selectedOption });
    };

    //allegati
    const handleScegliAllegato = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Funzione per aggiornare le note di un allegato specifico
    const handleNotaChange = (index, nota) => {
        const updatedAllegati = allegati.map((allegato, idx) => {
            if (idx === index) {
                return { ...allegato, nota };
            }
            return allegato;
        });
        setAllegati(updatedAllegati);
    };

    // Funzione per aggiungere nuovi file
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const newAllegati = files.map(file => ({
            file,
            nota: '',
        }));
        setAllegati(prev => [...prev, ...newAllegati]);
    };

    // Funzione per verificare se il file è un'immagine
    const isImage = (file) => {
        return /\.(jpg|jpeg|png|gif)$/i.test(file.name);
    };

    const getFileNameFromUrl = (url) => {
        // Split the URL by slashes and get the last part
        if (url == null) return "";
        return url.split('/').pop();
    };


    // Render degli allegati con note e anteprime
    const renderAllegati = () => {
        return allegati.map((allegato, index) => (
            <div
                key={index}
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '20px', // Spaziatura tra immagine e nota
                    marginBottom: '20px',
                    width: '100%'
                }}
            >
                {/* Anteprima immagine */}
                {isImage(allegato.file) ?
                    <img
                        src={URL.createObjectURL(allegato.file)}
                        alt="Anteprima"
                        style={{
                            maxWidth: '150px', // Limita la larghezza dell'immagine
                            maxHeight: '150px',
                            objectFit: 'cover',
                            borderRadius: '8px', // Per bordi arrotondati (opzionale)
                            marginRight: '15px'
                        }}
                    />

                    : <div>Allegato {index}</div>
                }

                {/* Nota */}
                {flgNotaPerAllegato && (
                    <label className="input-filtro-cliente-articoli form-input">
                        <div className='etichetta'>Nota {index + 1}</div>
                        <textarea
                            rows={4}
                            className="valori"
                            type="text"
                            value={allegato.nota}
                            style={{
                                flex: 1, // Occupare lo spazio rimanente
                                height: '100px',
                                fontFamily: 'inherit',
                                fontSize: '14px',
                                padding: '8px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                            }}
                            onChange={(e) => handleNotaChange(index, e.target.value)}
                        />
                    </label>
                )}
            </div>
        ));
    };

    return (
        <Modal
            isOpen={visible}
            onRequestClose={() => setModalNuovoTask(false)}
            className="info-modal border-large"
            contentLabel="Example Modal"
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                    zIndex: 10,
                },
                content: {
                    zIndex: 11,
                },
            }}
        >
            <div>
                <IoClose
                    className="close-icon"
                    onClick={() => setModalNuovoTask(false)}
                />
            </div>

            <h3 className="info-modal-title" style={{ marginTop: '0px' }}>Nuova attività</h3>

            <div className='contact-form'>
                {
                    authCtx.impostazioni.tipoUtente == 0 &&
                    <label className="input-filtro-cliente-articoli form-input">
                        <div className='etichetta'>Destinatari*</div>
                        <Select
                            className='select-item'
                            options={portaleCtx.utenti}
                            value={dati.utenti}
                            onChange={handleDestinatariChange}
                            isSearchable
                            placeholder="Seleziona i destinatari"
                            isMulti={true}
                        />
                    </label>
                }

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Cliente\Fornitore* {flgClienteDigitato ? ' - DIGITATO' : ''}</div>
                    {
                        !flgClienteDigitato ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'  }}>
                                <Select
                                    className='select-item'
                                    options={portaleCtx.clienti}
                                    value={dati.cliente}
                                    onChange={handleClienteChange}
                                    isSearchable
                                    placeholder="Seleziona il cliente o fornitore"
                                    style={{ minWidth: '350px' }}
                                />
                                {
                                    authCtx.impostazioni.tipoUtente == 0 &&
                                    <FaKeyboard className={`tab tab-small blue`} style={{ marginLeft: '10px', widht: '15%' }} onClick={() => { setFlgClienteDigitato(true) }} />
                                }
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'  }}>
                                <input
                                    className='valori'
                                    type="text"
                                    placeholder=""
                                    value={dati.clienteDigitato}
                                    maxLength={50}
                                    onChange={(e) => setDati({ ...dati, clienteDigitato: e.target.value })}
                                />
                                <IoList className={`tab tab-small blue`} style={{ marginLeft: '10px' }} onClick={() => { setFlgClienteDigitato(false) }} />
                            </div>
                    }
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Commessa {flgCommessaDigitata ? ' - DIGITATA' : ''}</div>
                    {
                        !flgCommessaDigitata ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Select
                                    className='select-item'
                                    options={commesse}
                                    value={dati.commessa}
                                    onChange={handleCommessaChange}
                                    isSearchable
                                    placeholder="Seleziona la commessa"
                                    style={{ minWidth: '350px !important' }}
                                />
                                {
                                    authCtx.impostazioni.tipoUtente == 0 &&
                                    <FaKeyboard className={`tab tab-small blue`} style={{ marginLeft: '10px', widht: '15%' }} onClick={() => { setFlgCommessaDigitata(true) }} />
                                }
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <input
                                    className='valori'
                                    type="text"
                                    placeholder=""
                                    value={dati.commessaDigitata}
                                    maxLength={50}
                                    onChange={(e) => setDati({ ...dati, commessaDigitata: e.target.value })}
                                />
                                <IoList className={`tab tab-small blue`} style={{ marginLeft: '10px' }} onClick={() => { setFlgCommessaDigitata(false) }} />
                            </div>
                    }
                </label>

                {
                    authCtx.impostazioni.tipoUtente == 0 &&
                    <label className="input-filtro-cliente-articoli form-input">
                        <div className='etichetta'>Tipo richiesta*</div>
                        <Select
                            className='select-item'
                            options={portaleCtx.tipiRichiesta}
                            value={dati.tipoRichiesta}
                            onChange={handleTipoRichiestaChange}
                            isSearchable
                            placeholder="Seleziona il tipo richiesta"
                        />
                    </label>
                }


                {
                    authCtx.impostazioni.tipoUtente == 0 &&
                    <label className="input-filtro-cliente-articoli form-input">
                        <div className='etichetta'>Data scadenza</div>
                        <input
                            className='valori'
                            type="date"
                            placeholder=""
                            value={dati.dataScadenza}
                            style={{ width: '96%', padding: '4px 10px' }}
                            onChange={(e) => setDati({ ...dati, dataScadenza: e.target.value })}
                        />
                    </label>
                }



                <label className="input-filtro-cliente-articoli form-input" >
                    <div className='etichetta'>Modalità di caricamento allegati</div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <button onClick={() => { setFlgNotaPerAllegato(false); }} className={!flgNotaPerAllegato ? 'icon-button' : 'icon-button-outline'}
                            style={{ marginTop: '10px', height: '40px', width: '100%', paddingLeft: '8px', paddingRight: '8px', marginRight: '4px' }}
                        >
                            Nota singola
                        </button>
                        <button onClick={() => { setFlgNotaPerAllegato(true); }} className={flgNotaPerAllegato ? 'icon-button' : 'icon-button-outline'}
                            style={{ marginTop: '10px', height: '40px', width: '100%', paddingLeft: '8px', paddingRight: '8px', marginLeft: '4px' }}
                        >
                            Nota per ogni allegato
                        </button>
                    </div>
                </label>

                <label className="input-filtro-cliente-articoli form-input">
                    <div className='etichetta'>Allegati</div>
                    <button onClick={handleScegliAllegato} className={'icon-button'}
                        style={{ marginTop: '10px', marginBottom: '20px', height: '40px', width: '100%', paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        Scegli allegati <IoAttach className="icon" />
                        {dati.allegato != null ? dati.allegato[0].name : ""}
                    </button>
                </label>
                <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/*"
                />

                {/* Render degli allegati */}
                <div
                    id="allegati-container"
                    style={{
                        display: 'flex',
                        flexDirection: flgNotaPerAllegato == true ? 'column' : 'row',
                        overflowX: 'auto',
                        gap: '10px',
                        maxWidth: '100%',
                        padding: '10px 0',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {renderAllegati()}
                </div>

                {
                    !flgNotaPerAllegato &&
                    <label className="input-filtro-cliente-articoli form-input">
                        <div className='etichetta'>Messaggio*</div>
                        <textarea
                            rows={20}
                            className='valori'
                            type="text"
                            placeholder=""
                            value={dati.messaggio}
                            style={{ height: 'auto', fontFamily: 'inherit', fontSize: '15px', width: '100%' }}
                            maxLength={500}
                            onChange={(e) => setDati({ ...dati, messaggio: e.target.value })}
                        />
                    </label>
                }

                <div className="buttons" style={{ width: '90%', marginTop: '20px' }}>
                    <button
                        className="button red"
                        onClick={() => { setModalNuovoTask(false) }}>
                        Annulla <IoArrowUndoSharp className="text-icon-right" />
                    </button>
                    <button
                        className="button green"
                        onClick={() => {
                            checkDatiInseriti();
                        }}
                    >
                        Salva <IoCheckmarkCircle className="text-icon-right" />
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalNuovoTask;